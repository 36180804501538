import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';
import { useAuth } from '@contexts/MainAppContext';

const SignupPage = () => {
  const { isUserLoggedIn, isUserInformationLoading } = useAuth();

  useEffect(() => {
    if (isUserInformationLoading) {
      if (isUserLoggedIn) navigate('/dashboard');
      else navigate('/?showSignup=true');
    }
  }, [isUserInformationLoading, isUserLoggedIn]);

  return <SpinnerDotPage />;
};

export default SignupPage;
